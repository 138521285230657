import { Injectable } from '@angular/core'
import { ConnectMeterAction } from '@eliq/core/actions/connect-meter/connect-meter-action'
import { CoreDataStoreService } from '@eliq/core'
import { TranslateService } from '@ngx-translate/core'
import { EliqConnectService } from '@eliq/data-access/services/eliq-connect/eliq-connect.service'
import { ModalService } from '@eliq/ui/modal'
import { switchMap, take } from 'rxjs'

@Injectable()
export class RealConnectMeterAction implements ConnectMeterAction {
	constructor(
		private connectService: EliqConnectService,
		private dataStore: CoreDataStoreService,
		private dialogService: ModalService,
		private translateService: TranslateService,
	) {}

	invoke() {
		this.dataStore.user
			.pipe(
				switchMap((user) => this.connectService.getWebUri(user.id)),
				take(1),
			)
			.subscribe((uri) => {
				if (uri) {
					window.location.href = uri
				} else {
					this.dialogService.openErrorModal(
						this.translateService.instant('common.general_error'),
					)
				}
			})
	}
}
