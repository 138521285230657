import { Component, Input, OnInit, inject } from '@angular/core'
import { ImgSrcPipe } from '@eliq/core'
import { ImgSrcPipe as ImgSrcPipe_1 } from '@eliq/core/pipes/img-src.pipe'
import { EliqThemeService } from '@eliq/theme'
import { TranslateModule } from '@ngx-translate/core'
import { CompanyLogoComponent } from '@eliq/ui'
@Component({
	selector: 'eliq-login-greeting',
	templateUrl: './login-greeting.component.html',
	styleUrls: ['./login-greeting.component.css'],
	standalone: true,
	imports: [CompanyLogoComponent, TranslateModule, ImgSrcPipe_1],
})
export class LoginGreetingComponent {
	public themeService = inject(EliqThemeService)
	logoSize: string = '4rem';

	constructor() {
		this.logoSize = this.getLogoSize();
	}

	getLogoSize(): string {
		let logoSizeByCSS = this.themeService.getPropVal('login-logo-height');
		if (logoSizeByCSS)
			return logoSizeByCSS;
		
		return '4rem';
	}
}
