<div class="container" *ngIf="setupLoaded" [hidden]="loading">
	<div
		*ngIf="annualCardEnabled && (annualCard$ | async)"
		style="width: '100%'"
		class="card annual"
	>
		<eliq-annual-card
			(hide)="hideAnnual()"
			class="annual-card"
			[locId]="location.id"
			[greyBorder]="false"
			[lastYear]="now.getFullYear() - 1"
		></eliq-annual-card>
	</div>

	<!-- <svg>
		<defs>
			<filter x="0" y="0" width="1" height="1" id="legendBackgroundFilter">
				<feFlood flood-color="rgba(255,255,255,1)" result="bg" />
				<feMerge>
					<feMergeNode in="bg" />
					<feMergeNode in="SourceGraphic" />
				</feMerge>
			</filter>
		</defs>
	</svg> -->

	<div class="grid-container">
		<div
			class="box card consumption"
			*ngIf="!hasProductionOrExport && (location?.fuels?.length || 0) > 0"
		>
			<div *ngIf="!consumptionFailed; else consumptionFailedBlock">
				<eliq-consumption-container
					[locId]="location.id"
					[resolutionType]="resolutionType === 'month' ? resolutionTypeMonth : resolutionTypeDay"
					[periodType]="periodTypeMonth"
					[fuels]="fuels"
					[period]="latestMonthWithDataPeriod"
					[periodToDate]="periodToDate"
					[units]="units"
					[options]="{forecastThisMonth: true, forecastNextMonth: false, showSimhomes: true}"
					(loading)="childLoadingUpdate($event)"
				></eliq-consumption-container>
			</div>
		</div>

		<div
			*ngIf="hasProductionOrExport"
			class="box card"
			style="flex-grow: 0; justify-self: flex-start;"
		>
			<eliq-pv-home-card-container
				[unit]="unit"
				[locId]="location.id"
				[period]="period"
				[elecFuel]="elecFuel"
				(loading)="childLoadingUpdate($event)"
			></eliq-pv-home-card-container>
		</div>

		<div
			*ngIf="!consumptionFailed && (enabledHomeCards$ | async)?.['homechart']"
			class="two-box chart"
		>
			<eliq-homechart-container
				[locId]="location.id"
				[resolutionType]="resolutionType"
				[fuels]="fuels"
				[period]="period"
				[unit]="unit"
				[options]="{fontFamily: themeService.getProp('font')}"
				(loading)="childLoadingUpdate($event)"
			></eliq-homechart-container>
		</div>

		<div
			class="box"
			*ngIf="(enabledHomeCards$ | async)?.['co2-card'] as co2Opts"
		>
			<eliq-co2-card-container
				[period]="period"
				[locId]="location?.id"
			></eliq-co2-card-container>
		</div>

		<div
			class="box bills card"
			*ngIf="!miniTransactionsErrored && (enabledHomeCards$ | async)?.['mini-transactions']"
		>
			<eliq-mini-transactions-container
				(errored)="onMiniTransactionsError()"
			></eliq-mini-transactions-container>
		</div>

		<div
			class="box outage card"
			*ngIf="!hideBudget && (enabledHomeCards$ | async)?.['budget'] && (locationSupportsBudget | async)"
		>
			<eliq-budget-home-card-container
				[defaultUnit]="'energy'"
				[location]="location"
				(loading)="childLoadingUpdate($event)"
			></eliq-budget-home-card-container>
		</div>
		<div
			class="box claim-and-homeprofile card"
			*ngIf="(enabledHomeCards$ | async)?.['energy-advisor']"
		>
			<div *ngIf="showRenewOption === undefined" style="height: 100%;">
				<div class="loading-card">
					<eliq-spinner size="medium"></eliq-spinner>
				</div>
			</div>
			<div *ngIf="showRenewOption === true" style="height: 100%;">
				<eliq-contract-renewal-home-card
					[expiryDate]="contractDaysLeftDate"
					[agreementId]="agreementToRenewId"
				></eliq-contract-renewal-home-card>
			</div>
			<div *ngIf="showRenewOption === false" style="height: 100%;">
				<eliq-energy-advisor-card-container
					style="height: 100%;"
				></eliq-energy-advisor-card-container>
			</div>
		</div>

		<!-- Old Home Info Card - single card -->
		<div
			class="box card"
			style="flex-grow: 0;"
			*ngIf="(enabledHomeCards$ | async)?.['info-card'] as infoCard"
		>
			<eliq-info-card
				[title]="(infoCard?.['title'] || 'home_info_card.title') | translate"
				[text]="(infoCard?.['description'] ||'home_info_card.description') | translate"
				[linkText]="(infoCard?.['linkText'] ||'home_info_card.link_text') | translate"
				[link]="infoCard?.['linkUrl']"
				[fullLink]="false"
				[cardStyle]="infoCard?.['cardStyle'] || {}"
				[linkStyle]="infoCard?.['linkStyle'] || {}"
				[imageStyle]="infoCard?.['imageStyle'] || {}"
				[imgSrc]="infoCard?.['imgSrc'] || undefined"
			></eliq-info-card>
		</div>

		<!-- New Home Info cards handling - multiple cards. Ordering based on configuration placement -->
		<div
			*ngFor="let infoCard of getRecordEntries((enabledHomeCards$ | async)?.['info-cards'])"
			class="box card"
			style="flex-grow: 0;"
		>
			<eliq-info-card
				[title]="(infoCard?.['title'] || 'home_info_card.title') | translate"
				[text]="(infoCard?.['description'] ||'home_info_card.description') | translate"
				[linkText]="(infoCard?.['linkText'] ||'home_info_card.link_text') | translate"
				[link]="infoCard?.['linkUrl']"
				[fullLink]="false"
				[cardStyle]="infoCard?.['cardStyle'] || {}"
				[linkStyle]="infoCard?.['linkStyle'] || {}"
				[imageStyle]="infoCard?.['imageStyle'] || {}"
				[imgSrc]="infoCard?.['imgSrc'] || undefined"
			></eliq-info-card>
		</div>

		<div class="box" *ngIf="(enabledHomeCards$ | async)?.['smr-card']">
			<eliq-smr-card></eliq-smr-card>
		</div>

		<eliq-connect-homecard
			style="
				box-sizing: border-box;
			"
			[ngStyle]="{
				'display': softShowEliqConnectCard ? 'block' : 'none'
			}"
			(showCard)="softShowEliqConnectCard = $event"
			*ngIf="(enabledHomeCards$ | async)?.['eliq-connect'] && integrationless && (showEliqConnectCard$ | async)"
		></eliq-connect-homecard>

		<div
			*ngIf="dctCardEnabled"
			class="box card"
			style="flex-grow: 0; justify-self: flex-start;"
		>
			<eliq-peak-tariffs-home-card
				style="height: 100%;"
				[locId]="location.id"
				[startYear]="startYear"
				[pv]="hasProductionOrExport"
			></eliq-peak-tariffs-home-card>
		</div>

		<eliq-home-profile-card
			class="box card"
			*ngIf="isHomeProfileCardVisible$ | async"
			(addProfileClick)="showHomeProfile()"
		></eliq-home-profile-card>
	</div>
	<div class="tester-helper" *ngIf="isUAT && false">
		<eliq-testing-helper></eliq-testing-helper>
	</div>
</div>

<div
	[style.display]="(setupLoaded && !loading) ? 'none' : 'block'"
	class="loading-container"
>
	<eliq-spinner [size]="'medium'"></eliq-spinner>
</div>

<ng-template #loadingBlock></ng-template>

<ng-template #otherBlock>
	<div style="text-align:center;" *ngIf="failed; else loadingBlock">
		<p class="primary-p2" translate>common.something_went_wrong</p>
		<p class="primary-p2" translate>common.please_try_again_later</p>
	</div>
</ng-template>

<ng-template #consumptionFailedBlock>
	<div class="consumption-failed">
		<p class="primary-p2">
			Your account has no data for the past 12 months and thus will not function
			properly.
		</p>
	</div>
</ng-template>

<ng-template #billingFailedBlock>
	<p class="secondary-p2" style="padding: 0px 24px; margin: 0;" translate>
		my_bills.failed_to_get_billing_accounts
	</p>
</ng-template>
