import {
	CoreDataStoreService,
	HomeProfile,
	LocationHttpService,
} from '@eliq/core'
import { EnvironmentService } from '@eliq/data-access'
import { AddHomeProfileAction } from '@eliq/core/actions/add-home-profile/add-home-profile-action'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { InsightsHomeProfileWizardModalComponent } from '@eliq/feature/insights/components/insights-home-profile-wizard-modal/insights-home-profile-wizard-modal.component'
import { ModalService } from '@eliq/ui/modal'
import { filter, map, mergeMap, NEVER, Observable, switchMap, take } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable()
export class RealAddHomeProfileAction implements AddHomeProfileAction {
	constructor(
		private dataStoreService: CoreDataStoreService,
		private httpService: LocationHttpService,
		private environmentService: EnvironmentService,
		private modalService: ModalService,
	) {}

	invoke(): Observable<void> {
		if (
			!this.environmentService.getFeatureFlag('insights-home-profile-wizard')
		) {
			return NEVER
		}
		return this.dataStoreService.getActiveLocation().pipe(
			take(1),
			filter((location) => location !== null),
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			map((location) => location!.id),
			switchMap((locationId) =>
				this.fetchProfile(locationId).pipe(
					map((profile) => ({ locationId, profile })),
				),
			),
			filter(({ profile }) => profile.isIncomplete),
			mergeMap(({ locationId, profile }) => {
				const dialogRef = this.modalService.openModal(
					InsightsHomeProfileWizardModalComponent,
				)
				const instance = <InsightsHomeProfileWizardModalComponent>(
					dialogRef.componentInstance
				)
				instance.locationId = locationId
				instance.properties = profile.$properties
				instance.propertyGroups = profile.$propertyGroups
				return dialogRef.afterClosed().pipe(take(1))
			}),
		)
	}

	private fetchProfile(locationId: number) {
		return this.httpService
			.getLocationHomeProfile(locationId)
			.pipe(map((profile) => new HomeProfile(this.environmentService, profile)))
	}
}
