import { Component, OnInit } from '@angular/core';

@Component({
  template: ''
})

// Empty dummy component used as space holder for component, but not to actually use it.
// Example: ExternalRedirectResolver in Routing
export class DummyComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
    console.error('Error: DummyComponent should not be instantiated directly.');
  }
}