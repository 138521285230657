import {
	Component,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core'
import { Router } from '@angular/router'
import {
	AuthService,
	TermsAcceptedDialogComponent,
	TermsCheckerService,
} from '@eliq/feature/auth'
import { CoreDataStoreService, JsonGetterService } from '@eliq/core'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ModalService } from '@eliq/ui/modal'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'
import { ConfigLoginType } from '../../models/config-login-type.model'
import { LoginConfigService } from '../../services/login-config/login-config.service'
import { JerseyHavingTroubleModalComponent } from '../having-trouble-modals/jersey-having-trouble-modal/jersey-having-trouble-modal.component'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { SigninSetupWizardComponent } from '../signin-setup-wizard/signin-setup-wizard.component'
import { SigninMagiclinkAccountnrComponent } from '../signin-types/signin-magiclink-accountnr/signin-magiclink-accountnr.component'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LinkComponent } from '@eliq/ui'
import { SigninTypesFooterComponent } from '../signin-types/signin-types-footer/signin-types-footer.component'
import { SigninMagiclinkComponent } from '../signin-types/signin-magiclink/signin-magiclink.component'
import { NgIf, AsyncPipe, DOCUMENT } from '@angular/common'
import { EliqThemeService } from '@eliq/theme'
import { CompanyLogoComponent } from '@eliq/ui'
import { RouterLink } from '@angular/router'
import { EnvironmentService } from '@eliq/data-access'
@Component({
	selector: 'eliq-signin-container',
	templateUrl: './signin-container.component.html',
	styleUrls: ['./signin-container.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		RouterLink,
		SigninMagiclinkComponent,
		SigninTypesFooterComponent,
		LinkComponent,
		SigninMagiclinkAccountnrComponent,
		SigninSetupWizardComponent,
		AsyncPipe,
		ImgSrcPipe,
		CompanyLogoComponent,
	],
})
export class SigninContainerComponent implements OnInit {
	@Input() integrationless = false
	@Output() maximizeLogin: EventEmitter<boolean> = new EventEmitter<boolean>()

	public expandLogin = false

	// this is set depending on size of the card's container. when it goes below a certain threshold, the card will expand (and block the background picture)
	private signinCardExpandThreshholdPx = 400
	private signinCardElement!: HTMLElement
	public signinCardsShouldExpand = false

	public loginState = 'logging_in' // "logging_in", "setting_up"

	public loginTypes!: ConfigLoginType[]
	public currentLoginType!: ConfigLoginType

	public themeService = inject(EliqThemeService)

	public showHelpButtonOnInitialState$ =
		this.loginConfig.showHelpButtonOnInitialState()
	public signUpUrl = ''
	constructor(
		private router: Router,
		private modal: ModalService,
		private env: EnvironmentService,
		private coreDS: CoreDataStoreService,
		private configService: JsonGetterService,
		private loginConfig: LoginConfigService,
		private auth: AuthService,
		private termsChecker: TermsCheckerService,
	) {}

	private document = inject(DOCUMENT) as Document
	private window = inject(DOCUMENT).defaultView as Window

	ngOnInit() {
		this.signinCardsShouldExpand = this.window?.innerWidth <= 410

		this.loginConfig
			.getLoginTypes()
			.pipe(take(1))
			.subscribe((loginTypes) => {
				this.currentLoginType = loginTypes[0]
				this.loginTypes = loginTypes
			})

		if (this.integrationless) {
			this.signUpUrl = '/'
		} else {
			this.loginConfig
				.getSignUpUrl()
				.pipe(take(1))
				.subscribe((url) => {
					this.signUpUrl = url ?? null
				})
		}
	}

	ngAfterViewInit() {
		this.signinCardElement = this.document.getElementById('signin-card-123')!
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	windowResized(_ = {}) {
		this.signinCardsShouldExpand =
			this.signinCardElement.offsetWidth < this.signinCardExpandThreshholdPx
	}

	loginHelpClicked() {
		this.modal.openModal(JerseyHavingTroubleModalComponent)
	}

	logInDone() {
		this.checkUserTerms()
			.pipe(
				tap((accepted) => {
					if (accepted) {
						this.termsChecker.setTermsAccepted(true)
						this.maximizeLogin.emit(true)
						this.expandLogin = true
						this.loginState = 'setting_up'
					} else {
						// user declined terms
						this.auth.logout()
						window?.location.reload()
					}
				}),
				catchError((err, caught) => {
					this.router.navigate([
						this.env.useIntegrationlessWelcomePage() ? '/welcome' : '/home',
					])
					return caught
				}),
				switchMap(() =>
					this.configService
						.getMeterConnectionConfig()
						.pipe(
							map((config) =>
								config.requireAfterLogin
									? { queryParams: { requireConnectMeter: true } }
									: undefined,
							),
						),
				),
			)
			.subscribe((extras) => {
				this.router.navigate(
					[this.env.useIntegrationlessWelcomePage() ? '/welcome' : '/home'],
					extras,
				)
			})
	}

	setupWizardDone() {
		this.router.navigate([this.env.useIntegrationlessWelcomePage() ? '/welcome' : '/home'])
	}

	private checkUserTerms() {
		return this.configService.isClientHandlingTerms().pipe(
			switchMap((isClientHandlingTerms) => {
				if (isClientHandlingTerms) {
					return of(true)
				} else {
					return this.coreDS.user.pipe(
						// previously the return type of this function was Observable<Observable<boolean>> but switchmap removes the intermediary!!!!
						switchMap((user) => {
							if (user.terms_accepted) return of(true)
							else {
								// openTermsActionDialog returns an observable of either true or false (error = false)
								return forkJoin({
									termsAndConditionsUrl:
										this.configService.getTermsAndConditionsLink(),
									privacyPolicyUrl: this.configService.getPrivacyPolicyLink(),
								}).pipe(
									switchMap((response) => {
										const dialogRef = this.modal.openModal(
											TermsAcceptedDialogComponent,
										)
										const instance = <TermsAcceptedDialogComponent>(
											dialogRef.componentInstance
										)
										instance.userId = user.id
										instance.termsLink = response.termsAndConditionsUrl
										instance.privacyPolicyLink = response.privacyPolicyUrl

										return <Observable<boolean>>dialogRef.afterClosed()
									}),
								)
							}
						}),
					)
				}
			}),
		)
	}
}
