import {
	PeriodType,
	ResolutionType,
	possibleResolutionForPeriod,
} from '../public_api'
import { DateHelper } from '@eliq/util'
import {
	startOfISOWeek,
	endOfISOWeek,
	addDays,
	subMonths,
	addMonths,
	addMinutes,
} from 'date-fns'

export class Period {
	private dates: Date[] = []
	private dateCalc: DateHelper
	private periodType: PeriodType

	// selectedPeriod is for example which month or which day which should represent the period.
	constructor(periodType: PeriodType, selectedPeriod: Date) {
		this.dateCalc = DateHelper.getInstance()
		this.periodType = periodType

		if (periodType === PeriodType.Day) {
			const nHours = 24
			for (let i = 0; i < nHours; i++) {
				this.dates.push(
					new Date(
						selectedPeriod.getFullYear(),
						selectedPeriod.getMonth(),
						selectedPeriod.getDate(),
						i,
					),
				)
			}
		} else if (periodType === PeriodType.Week) {
			// moment is great. get the first and last days of the requested week.
			// 9 months later: moment is not great. its huge. so now we use date-fns :)
			// selectedperiod is a date object passed in. thats where we get the requested week.

			const firstWeekday = startOfISOWeek(selectedPeriod)
			const lastWeekday = endOfISOWeek(selectedPeriod)

			let day = firstWeekday

			// while day is smaller than the last day of the week,
			// push day to dates array. simple! it works so well!
			while (day <= lastWeekday) {
				this.dates.push(day)
				const copiedDay = new Date(day.getTime())
				day = addDays(copiedDay, 1)
			}
		} else if (periodType === PeriodType.Month) {
			const nDays = this.dateCalc.daysInMonth(
				selectedPeriod.getMonth() + 1,
				selectedPeriod.getFullYear(),
			)
			for (let i = 0; i < nDays; i++) {
				this.dates.push(
					new Date(
						selectedPeriod.getFullYear(),
						selectedPeriod.getMonth(),
						i + 1,
					),
				)
			}
		} else if (periodType === PeriodType.Year) {
			const nMonths = 12
			for (let i = 0; i < nMonths; i++) {
				this.dates.push(new Date(selectedPeriod.getFullYear(), i))
			}
		} else if (periodType === PeriodType.THIRTEENMONTH) {
			let workingDate = new Date()

			while (this.dates.length < 13) {
				this.dates.push(workingDate)
				workingDate = subMonths(workingDate, 1)
			}
			this.dates = this.dates.reverse()
		} else if (periodType === PeriodType.Minute) {
			//e.g. if time is 08:59:59 then past will be 08:59 and current 09:00
			selectedPeriod.setTime(selectedPeriod.getTime() + 1000 * 60)
			const n10seconds = 6
			for (let i = 0; i < n10seconds; i++) {
				this.dates.push(
					new Date(
						selectedPeriod.getFullYear(),
						selectedPeriod.getMonth(),
						selectedPeriod.getDate(),
						selectedPeriod.getHours(),
						selectedPeriod.getMinutes(),
						10 * i,
					),
				)
			}
		} else {
		}
	}

	public getPeriodType(): PeriodType {
		return this.periodType
	}

	public getDates(): Date[] {
		return [...this.dates]
	}

	public getFirstDate(): Date {
		return new Date(this.dates[0])
	}

	public getLastDate(): Date {
		return new Date(this.dates.slice(-1)[0])
	}

	public getPossibleResolutions(): ResolutionType[] {
		return possibleResolutionForPeriod(this.periodType)
	}
	/**
	 * The period class currently does not support different resolutions for the supported period types,
	 * so for now we implement this method which can do some conversions for any users.
	 * Currently only supports periodtype = day and resolutiontype = 30min.
	 * @param resolution The wanted resolution.
	 */
	public getDatesForResolution(resolution: ResolutionType): Date[] {
		if (
			this.periodType === PeriodType.Day &&
			resolution === ResolutionType.ThirtyMin
		) {
			const dates: any[] = []
			this.getDates().forEach((date) => {
				dates.push(date)
				dates.push(addMinutes(date, 30))
			})
			return dates
		} else {
			return this.dates
		}
	}
}
