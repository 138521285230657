<div class="account-balance-container">
	<p class="secondary-p2" style="margin: 0;">
		{{'my_bills.account_balance' | translate}}
	</p>
	<div class="values" *ngIf="accountBalance.amount !== undefined">
		<span class="primary-h2 account-balance-value">
			{{accountBalance.amount | eliqNumber:'cost':'1.0-2'}}
		</span>
		<span class="secondary-p3 updated-text">
			{{'my_bills.updated_date' | translate: { date: updatedDateString } | lowercase }}
		</span>
	</div>
	<div class="values" *ngIf="accountBalance.amount === undefined">
		<p class="secondary-p2">{{'common.something_went_wrong' | translate}}</p>
	</div>
</div>
