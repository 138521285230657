import { NgIf } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core'
import { JsonGetterService } from '@eliq/core';
import { EliqThemeService } from '@eliq/theme';
import { LinkComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'eliq-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	standalone: true,
	imports: [LinkComponent, NgIf, TranslateModule],
})
export class FooterComponent implements OnInit {
	@Input() footerLink?: string;
	@Input() footerLinkText?: string;
	@Input() footerText?: string;
	@Input() policyLink?: string;


	public themeService = inject(EliqThemeService)
	public config = inject(JsonGetterService)

	ngOnInit() {
		this.config.getFooterInfo().subscribe((res) => {
			this.footerLink = res.link
			this.footerText = res.text
			this.footerLinkText = res.linkText
			this.policyLink = res.policyLink
		})

	}
}
